<template>
  <div class="mb-5">
    <header class="svg-logo d-flex align-center justify-center">
      <v-img class="mr-5" max-width="50px" src="../../../public/assets/img/svg/palindrome-calculator.svg"></v-img>
      <h1 class="text-h5">Palindrome Calculator</h1>
    </header>

    <v-form ref="form" class="mt-4">
      <p>Privacy policy: The Android app doesn't collect any data.</p>

      Credit:
      <Url :dark-mode="$vuetify.theme.isDark" href="https://www.youtube.com/watch?v=bN8PE3eljdA"> James Grime </Url>

      <v-checkbox v-model="isTutorial" label="Explain calculation process"></v-checkbox>

      <v-text-field
        ref="input"
        v-model.trim="inputNumberString"
        :rules="rules"
        autofocus
        class="mt-5"
        label="Type a number with at least 2 digits"
        type="text"
        @keypress="preventNonNumber"
        @keydown.up="incrementNumber"
        @keydown.down="decrementNumber"
      ></v-text-field>

      <transition name="slide">
        <div v-if="number && number.toString().length > 1 && output" class="output">
          How does this {{ commaSeparated(number.toString().length) }}-digit number turn into a palindrome?<br />
          {{ commaSeparated(number) }}

          <div v-for="step of output.steps" :key="step.iteration">
            <div v-show="isTutorial">
              <div v-if="step.tutorial">
                {{ getInstruction(step) }}
              </div>
              <v-icon>{{ mdiArrowDown }}</v-icon>
            </div>
            <span class="iteration">#{{ step.iteration }})</span>
            {{ step.calculation }}
          </div>

          <v-btn class="mt-4 mb-2" @click="continueCalculation" v-if="output.isCanContinue"> Continue </v-btn>

          <div v-show="!output.isCanContinue">
            <v-icon color="green">{{ mdiCheckBold }}</v-icon>
            Success!
          </div>
        </div>
      </transition>
    </v-form>
  </div>
</template>

<script>
import Big from "big.js";
import { convertToPalindrome } from "@/views/products/calculators";
import { mdiArrowDown, mdiCheckBold } from "@mdi/js";
import Url from "@/components/Url";
import { mapGetters } from "vuex";

Big.PE = Infinity;

export default {
  name: "PalindromeCalculator",
  components: { Url },
  data() {
    return {
      inputNumberString: "",
      number: null,
      isTutorial: true,
      rules: [number => !isNaN(number.toString()) || "Must be a number"],
      output: {},
      mdiCheckBold,
      mdiArrowDown
    };
  },
  watch: {
    inputNumberString(inputNumberString) {
      this.prepareToConvertToPalindrome(inputNumberString);
    },
    isTutorial() {
      this.prepareToConvertToPalindrome(this.inputNumberString);
    }
  },
  computed: {
    ...mapGetters(["commaSeparated"]),
    calculationSteps() {
      return this.output.steps;
    }
  },
  methods: {
    async prepareToConvertToPalindrome(inputNumberString, iStart) {
      inputNumberString = inputNumberString || 0;
      this.number = new Big(inputNumberString);
      if (!this.$refs.form.validate() || this.number.lte(9)) {
        return;
      }
      this.output = await convertToPalindrome(this.number.toString(), iStart);
    },
    continueCalculation() {
      const { lastNumber, lastIteration } = this.output;
      this.prepareToConvertToPalindrome(lastNumber, lastIteration);
    },
    getInstruction(step) {
      const iteration = new Big(step.iteration.toString().replace(/,/g, ""));
      if (!iteration.gt(3)) {
        return step.tutorial;
      }
      if (iteration.eq(4)) {
        return "Again";
      }
      return "";
    },
    preventNonNumber(e) {
      if (isNaN(e.key) && e.key !== "-") {
        e.preventDefault();
      }
    },
    incrementNumber() {
      const incrementedNumber = new Big(this.inputNumberString || 9).add(1);
      this.inputNumberString = incrementedNumber.toString();
    },
    decrementNumber() {
      const decrementedNumber = new Big(this.inputNumberString || 11).sub(1);
      if (!decrementedNumber.gt(9)) {
        return;
      }
      this.inputNumberString = decrementedNumber.toString();
    }
  }
};
</script>

<style scoped>
.output {
  white-space: nowrap;
  overflow-y: hidden;
}

/*noinspection CssUnusedSymbol*/
.slide-leave,
.slide-enter-to {
  max-height: 100vh;
}

.iteration {
  color: blue;
}

@media (prefers-color-scheme: dark) {
  .iteration {
    color: cyan;
  }
}
</style>
